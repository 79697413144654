import React from 'react'

const Fancy = () => {
    return (
        <React.Fragment>
            <div className="right_col" role="main" style={{ height: '100%' }}>
                <div className="right_col" role="main" style={{ height: '100%' }}>
                    <div className="biab_body biab_fluid" id="biab_body">
                        <div className="biab_wrapper js-wrapper" style={{ width: '1350px' }}>
                            <div id="biab_message-dialog" className="biab_message-dialog-wrapper" />
                            <div className="biab_table-tabs js-table-tabs" />
                            <div className="biab_page-wrapper">
                                <div className="biab_page-holder">
                                    <div className="biab_page-container">
                                        <div id="biab_page" className="biab_page js-page">
                                            <div className="biab_banner biab_fluid-hidden biab_banner-main js-banner-main biab_hide" />
                                            <div className="biab_columns-holder js-columns-holder biab_has-aside-left" style={{ maxHeight: '722.5px' }}>
                                                <div className="loading">
                                                    <div className="biab_loading-spinner js-inplay-spinner " style={{ display: 'block' }} /></div>
                                                <div id="biab_content" className="biab_content" style={{ minHeight: '541.5px' }}>
                                                    <div>
                                                        <div className="biab_sub-page-banner js-sub-page-banner">
                                                            <div className="biab_banner-wrapper " style={{ maxHeight: '100%' }} /></div>
                                                        <div className="js-search-region biab_search biab_hidden-xs">
                                                            <div className="biab_search-container">
                                                                <div className="biab_search-page-title biab_visible-xs">Search</div>
                                                                <div className="js-search-results-container biab_search-results-container biab_hide" style={{ display: 'none' }}>
                                                                    <div className="biab_search-results-info js-search-results-info" /></div>
                                                                <div className="js-scroll-start" />
                                                                <div className="biab_scrollable js-scrollable biab_hide" style={{ display: 'none', position: 'relative', height: '511.5px', overflow: 'auto' }}>
                                                                    <div className="biab_scrollable-content js-scrollable-content" style={{ marginBottom: '511.5px' }}>
                                                                        <div className="biab_loading-spinner js-spinner biab_hide" style={{ display: 'none' }} />
                                                                        <div className="js-search-results-container biab_search-results-container biab_hide" style={{ display: 'none' }}>
                                                                            <div className="biab_search-results js-search-results" />
                                                                            <div className="js-show-more biab_show-more biab_hide">Show more</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="js-footer" style={{ marginTop: '0px' }}>
                                                                        <div id="biab_footer">
                                                                            <div className="biab_footer-inner biab_footer-desktop biab_hidden-xs">
                                                                                <div className="biab_footer-content">
                                                                                    <p>Indibet Exchange is the newest sports exchange operator that offers incredible odds, liquidity and markets. We offer online odds with back and lay betting - a marketplace for you
                                                                                        to bet on the outcome of thousands of events. Enjoy an exciting variety of sports including football, tennis, cricket, horseracing, American football, basketball and more. Available
                                                                                        in various languages - Indibet Exchange is the ultimate destination for sports exchange betting.
                                                                                        <a href="#webchat_widget">.</a></p>
                                                                                    <ul className="biab_footer-nav">
                                                                                        <li>
                                                                                            <a href="/customer/pages/en/responsible_gambling">Responsible Gambling</a></li>
                                                                                        <li>
                                                                                            <span className="biab_separator" />
                                                                                            <a href="/customer/pages/en/terms_and_conditions">Terms and Conditions</a></li>
                                                                                        <li>
                                                                                            <span className="biab_separator" />
                                                                                            <a href="/customer/pages/en/rules_and_regulations">Rules and Regulations</a></li>
                                                                                        <li>
                                                                                            <span className="biab_separator" />
                                                                                            <a href="/customer/pages/en/privacy_policy">Privacy Policy</a></li>
                                                                                    </ul>
                                                                                    <ul className="biab_footer-icons">
                                                                                        <li style={{ margin: '0px 20px 0px 0px' }}>
                                                                                            <a href="https://ex.indibet.com/customer/" className="biab_adults-only">18+</a></li>
                                                                                        <li style={{ margin: '0px 20px 0px 0px' }}><a className="biab_gamble-aware" href="https://www.begambleaware.org/" target="_blank" rel="noopener noreferrer">GambleAware</a></li>
                                                                                        <li style={{ width: '48px', margin: '0px' }}><a className="biab_curacao" href="#">Curacao</a>
                                                                                            <div data-apg-seal-id="7d92de7c-528e-4fc8-bb73-adb7ceb3caf4" data-apg-image-size={128} data-apg-image-type="basic-small">
                                                                                                <div style={{
                                                                                                    display: 'block', position: 'relative', overflow: 'hidden', maxWidth: '128px', minWidth: '32px', backgroundImage:
                                                                                                        'url("https://7d92de7c-528e-4fc8-bb73-adb7ceb3caf4.snippet.antillephone.com/54f396e0-b046-49b1-9cb3-0c69281d7ea9-beacon.png")'
                                                                                                }}><a target="_blank" rel="nonoopener" href="https://validator.antillephone.com/validate?domain=ex.indibet.com&seal_id=6c6ba157fb30f9d753db1a0cb76ab8f9b9a388a04885b559fa75dd1655b2a8b67565760ea68ea2789a4f6b3976b35e39&stamp=de56848e1c69c8425cf40040256dd574"><img alt="" src="https://7d92de7c-528e-4fc8-bb73-adb7ceb3caf4.snippet.antillephone.com/sealassets/de56848e1c69c8425cf40040256dd574-ex.indibet.com-6c6ba157fb30f9d753db1a0cb76ab8f9b9a388a04885b559fa75dd1655b2a8b67565760ea68ea2789a4f6b3976b35e39-c2VhbC5wbmc%3D?status=valid" style={{ width: '100%', height: 'auto' }} /></a></div>
                                                                                            </div>
                                                                                        </li>
                                                                                    </ul>
                                                                                    <p />
                                                                                </div>
                                                                                <ul className="biab_footer-nav" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="js-content-region">
                                                            <div>
                                                                <div className="js-content-wrapper">
                                                                    <div className="biab_market biab_handicap-market js-market biab_mmv-market  ">
                                                                        <div className="js-market-header-region">
                                                                            <div> </div>
                                                                        </div>
                                                                        <div className="js-drop-down-menu-region" />
                                                                        <div className="js-scroll-start" />
                                                                        <div className="biab_scrollable js-scrollable js-toggle-content" style={{ position: 'relative', height: '893.5px', overflow: 'auto' }}>
                                                                            <div className="biab_scrollable-content js-scrollable-content" style={{ marginBottom: '326px' }}>
                                                                                <div id="multiMarketContainer">
                                                                                    <div>
                                                                                        <div className="js-content-wrapper">
                                                                                            <div className="biab_multi-markets-content js-markets-content">
                                                                                                <div>
                                                                                                    <div />
                                                                                                    <div>
                                                                                                        <div className="biab_fancy-page biab_fancy-mmv-page">
                                                                                                            <div className="biab_multi-market biab_has-collapse js-multi-market">
                                                                                                                <div className="biab_multi-market-title js-collapse-toggler"> Fancy
                                                                                                                    <div className="biab_market-icons" /></div>
                                                                                                                <div className="biab_market-body js-fancy-markets-region">
                                                                                                                    <div>
                                                                                                                        <div className="js-fancy-markets-list-region biab_fancy-markets-list">
                                                                                                                            <div>
                                                                                                                                <div className="js-fancy-lines-toggle-wrapper biab_fancy-markets-toggle biab_fancy-swap">
                                                                                                                                    <div className="biab_fancy-title">2nd Innings Fancy</div>
                                                                                                                                    <table className="biab_market-table">
                                                                                                                                        <colgroup>
                                                                                                                                            <col />
                                                                                                                                            <col className="biab_table-col" width="16%" />
                                                                                                                                            <col className="biab_table-col" width="8%" />
                                                                                                                                            <col className="biab_table-col" width="8%" />
                                                                                                                                            <col className="biab_table-col" width="8%" />
                                                                                                                                            <col className="biab_table-col " width="8%" />
                                                                                                                                            <col className="biab_table-col " width="8%" />
                                                                                                                                            <col className="biab_table-col " width="8%" />
                                                                                                                                        </colgroup>
                                                                                                                                        <thead>
                                                                                                                                            <tr>
                                                                                                                                                <th className="biab_no-bg" />
                                                                                                                                                <th className="biab_no-bg" />
                                                                                                                                                <th className="biab_no-bg biab_fancy-market-column biab_back-all-column " />
                                                                                                                                                <th className="biab_no-bg biab_fancy-market-column biab_back-all-column " />
                                                                                                                                                <th className="biab_no-bg biab_fancy-market-column biab_back-all-column ">No</th>
                                                                                                                                                <th className="biab_no-bg biab_fancy-market-column biab_lay-all-column ">Yes</th>
                                                                                                                                                <th className="biab_no-bg biab_fancy-market-column biab_lay-all-column " />
                                                                                                                                                <th className="biab_no-bg biab_fancy-market-column biab_lay-all-column " />
                                                                                                                                            </tr>
                                                                                                                                        </thead>
                                                                                                                                    </table>
                                                                                                                                    <div className="js-fancy-markets-list">
                                                                                                                                        <div className="biab_market-table-wrapper biab_fancy-market-item">
                                                                                                                                            <table className="biab_market-table">
                                                                                                                                                <colgroup>
                                                                                                                                                    <col />
                                                                                                                                                    <col className="biab_table-col" width="16%" />
                                                                                                                                                    <col className="biab_table-col" width="8%" />
                                                                                                                                                    <col className="biab_table-col" width="8%" />
                                                                                                                                                    <col className="biab_table-col" width="8%" />
                                                                                                                                                    <col className="biab_table-col" width="8%" />
                                                                                                                                                    <col className="biab_table-col" width="8%" />
                                                                                                                                                    <col className="biab_table-col " width="8%" />
                                                                                                                                                    <col className="biab_table-col " width="8%" />
                                                                                                                                                    <col className="biab_table-col " width="8%" />
                                                                                                                                                </colgroup>
                                                                                                                                                <tbody>
                                                                                                                                                    <tr>
                                                                                                                                                        <td className="biab_fancy-market-selection-name">2nd Innings 10 Over Line</td>
                                                                                                                                                        <td className="biab_fancy-market-selection-tm js-market-total-matched" style={{ borderRight: 'none',borderLeft: 'none' }} />
                                                                                                                                                        <td className="biab_fancy-market-selection-tm js-market-total-matched" style={{ borderRight: 'none',borderLeft: 'none' }} />
                                                                                                                                                        <td className="biab_fancy-market-selection-tm js-market-total-matched"style={{ borderRight: 'none',borderLeft: 'none' }} />
                                                                                                                                                        <td className="biab_bet js-fm-back-2 biab_grey-cell biab_bet-back biab_empty-cell biab_back-2 biab_line-market-cell"
                                                                                                                                                            data-selection-id={15316} data-handicap={0} style={{ borderRight: 'none',borderLeft: 'none' }} />
                                                                                                                                                         <td className="biab_bet js-fm-back-1 biab_grey-cell biab_bet-back biab_empty-cell biab_back-1 biab_line-market-cell"
                                                                                                                                                            data-selection-id={15316} data-handicap={0} ><i class="fas fa-arrow-circle-down"></i></td>
                                                                                                                                                        <td className="biab_bet js-fm-back-0 biab_blue-cell biab_bet-back biab_empty-cell biab_back-0 biab_line-market-cell"
                                                                                                                                                            data-selection-id={15316} data-handicap={0}>
                                                                                                                                                            <div className="biab_cell-view">
                                                                                                                                                                <div className="js-bet biab_bet-cell">
                                                                                                                                                                    <div className="biab_bet-content biab_make-offer-wrapper-lg">
                                                                                                                                                                        
                                                                                                                                                                        <span className="biab_make-offer biab_make-offer-lg">Make Offer</span></div>
                                                                                                                                                                </div>
                                                                                                                                                            </div>
                                                                                                                                                        </td>
                                                                                                                                                        <td class="js-lay-cell-0 biab_green-cell biab_bet biab_bet-lay biab_lay-0 biab_line-market-cell" data-id="15316_hc_0" data-selection-id="15316" data-handicap="0">
                                                                                                                                                            <div class="biab_cell-view">
                                                                                                                                                                <div class="js-bet biab_bet-cell" data-toggle="tooltip" data-placement="auto" data-text="This means you can <strong>bet against</strong> Total Runs to be less than runs <strong>or</strong> greater. Your bet would be executed at odds 2.0">
                                                                                                                                                                    <div class="biab_bet-content biab_has-amount">
                                                                                                                                                                        
                                                                                                                                                                        <span class="js-odds biab_odds">22</span>
                                                                                                                                                                        
                                                                                                                                                                        <span class="biab_bet-amount">81</span></div>
                                                                                                                                                                </div>
                                                                                                                                                            </div>
                                                                                                                                                        </td>
                                                                                                                                                        <td class="biab_bet js-fm-lay-1 biab_grey-cell biab_bet-lay biab_empty-cell biab_lay-1 biab_line-market-cell" data-selection-id="15316" data-handicap="0" style={{borderRight: 'none'}}>Min <input id="inpid" class="min-inp" type="number"/></td>
                                                                                                                                                        <td class="biab_bet js-fm-lay-1 biab_grey-cell biab_bet-lay biab_empty-cell biab_lay-1 biab_line-market-cell" data-selection-id="15316" data-handicap="0" style={{borderRight: 'none'}}>Max <input id="inpid" class="min-inp" type="number"/></td>
                                                                                                                                                    </tr>
                                                                                                                                                </tbody>
                                                                                                                                            </table>
                                                                                                                                            <div className="js-inline-placement-component">
                                                                                                                                                <div className="biab_inline-placement" /></div>
                                                                                                                                            <div className="js-mobile-betslip" />
                                                                                                                                            <div className="js-status-wrapper">
                                                                                                                                                <div /></div>
                                                                                                                                        </div>
                                                                                                                                        <div className="biab_market-table-wrapper biab_fancy-market-item">
                                                                                                                                            <table className="biab_market-table">
                                                                                                                                                <colgroup>
                                                                                                                                                    <col />
                                                                                                                                                    <col className="biab_table-col" width="16%" />
                                                                                                                                                    <col className="biab_table-col" width="8%" />
                                                                                                                                                    <col className="biab_table-col" width="8%" />
                                                                                                                                                    <col className="biab_table-col" width="8%" />
                                                                                                                                                    <col className="biab_table-col" width="8%" />
                                                                                                                                                    <col className="biab_table-col" width="8%" />
                                                                                                                                                    <col className="biab_table-col " width="8%" />
                                                                                                                                                    <col className="biab_table-col " width="8%" />
                                                                                                                                                    <col className="biab_table-col " width="8%" />
                                                                                                                                                </colgroup>
                                                                                                                                                <tbody>
                                                                                                                                                    <tr>
                                                                                                                                                        <td className="biab_fancy-market-selection-name">2nd Innings 15 Over Line</td>
                                                                                                                                                        <td className="biab_fancy-market-selection-tm js-market-total-matched" style={{ borderRight: 'none',borderLeft: 'none' }} />
                                                                                                                                                        <td className="biab_fancy-market-selection-tm js-market-total-matched"  style={{ borderRight: 'none',borderLeft: 'none' }} />
                                                                                                                                                        <td className="biab_fancy-market-selection-tm js-market-total-matched" style={{ borderRight: 'none',borderLeft: 'none' }} />
                                                                                                                                                        <td className="biab_bet js-fm-back-2 biab_grey-cell biab_bet-back biab_empty-cell biab_back-2 biab_line-market-cell"
                                                                                                                                                            data-selection-id={15316} data-handicap={0} style={{ borderRight: 'none',borderLeft: 'none' }} />
                                                                                                                                                         <td className="biab_bet js-fm-back-1 biab_grey-cell biab_bet-back biab_empty-cell biab_back-1 biab_line-market-cell"
                                                                                                                                                            data-selection-id={15316} data-handicap={0} ><i class="fas fa-arrow-circle-down"></i></td>
                                                                                                                                                        <td className="biab_bet js-fm-back-0 biab_blue-cell biab_bet-back biab_empty-cell biab_back-0 biab_line-market-cell"
                                                                                                                                                            data-selection-id={15316} data-handicap={0}>
                                                                                                                                                            <div className="biab_cell-view">
                                                                                                                                                                <div className="js-bet biab_bet-cell" data-original-title="true">
                                                                                                                                                                    <div className="biab_bet-content biab_make-offer-wrapper-lg">
                                                                                                                                                                        
                                                                                                                                                                        <span className="biab_make-offer biab_make-offer-lg">Make Offer</span></div>
                                                                                                                                                                </div>
                                                                                                                                                            </div>
                                                                                                                                                        </td>
                                                                                                                                                        <td class="js-lay-cell-0 biab_green-cell biab_bet biab_bet-lay biab_lay-0 biab_line-market-cell" data-id="15316_hc_0" data-selection-id="15316" data-handicap="0">
                                                                                                                                                            <div class="biab_cell-view">
                                                                                                                                                                <div class="js-bet biab_bet-cell" data-toggle="tooltip" data-placement="auto" data-text="This means you can <strong>bet against</strong> Total Runs to be less than runs <strong>or</strong> greater. Your bet would be executed at odds 2.0">
                                                                                                                                                                    <div class="biab_bet-content biab_has-amount">
                                                                                                                                                                        
                                                                                                                                                                        <span class="js-odds biab_odds">22</span>
                                                                                                                                                                        
                                                                                                                                                                        <span class="biab_bet-amount">81</span></div>
                                                                                                                                                                </div>
                                                                                                                                                            </div>
                                                                                                                                                        </td>
                                                                                                                                                        <td class="biab_bet js-fm-lay-1 biab_grey-cell biab_bet-lay biab_empty-cell biab_lay-1 biab_line-market-cell" data-selection-id="15316" data-handicap="0" style={{borderRight: 'none'}}>Min <input  id="inpid"class="min-inp" type="number"/></td>
                                                                                                                                                        <td class="biab_bet js-fm-lay-1 biab_grey-cell biab_bet-lay biab_empty-cell biab_lay-1 biab_line-market-cell" data-selection-id="15316" data-handicap="0" style={{borderRight: 'none'}}>Max <input id="inpid" class="min-inp" type="number"/></td>
                                                                                                                                                    </tr>
                                                                                                                                                </tbody>
                                                                                                                                            </table>
                                                                                                                                            <div className="js-inline-placement-component">
                                                                                                                                                <div className="biab_inline-placement" /></div>
                                                                                                                                            <div className="js-mobile-betslip" />
                                                                                                                                            <div className="js-status-wrapper">
                                                                                                                                                <div /></div>
                                                                                                                                        </div>
                                                                                                                                        <div className="biab_market-table-wrapper biab_fancy-market-item">
                                                                                                                                            <table className="biab_market-table">
                                                                                                                                                <colgroup>
                                                                                                                                                    <col />
                                                                                                                                                    <col className="biab_table-col" width="16%" />
                                                                                                                                                    <col className="biab_table-col" width="8%" />
                                                                                                                                                    <col className="biab_table-col" width="8%" />
                                                                                                                                                    <col className="biab_table-col" width="8%" />
                                                                                                                                                    <col className="biab_table-col" width="8%" />
                                                                                                                                                    <col className="biab_table-col" width="8%" />
                                                                                                                                                    <col className="biab_table-col " width="8%" />
                                                                                                                                                    <col className="biab_table-col " width="8%" />
                                                                                                                                                    <col className="biab_table-col " width="8%" />
                                                                                                                                                </colgroup>
                                                                                                                                                <tbody>
                                                                                                                                                    <tr>
                                                                                                                                                        <td className="biab_fancy-market-selection-name">2nd Innings 20 Over Line</td>
                                                                                                                                                        <td className="biab_fancy-market-selection-tm js-market-total-matched"style={{ borderRight: 'none',borderLeft: 'none' }} />
                                                                                                                                                        <td className="biab_fancy-market-selection-tm js-market-total-matched"style={{ borderRight: 'none',borderLeft: 'none' }} />
                                                                                                                                                        <td className="biab_fancy-market-selection-tm js-market-total-matched"style={{ borderRight: 'none',borderLeft: 'none' }} />
                                                                                                                                                        <td className="biab_bet js-fm-back-2 biab_grey-cell biab_bet-back biab_empty-cell biab_back-2 biab_line-market-cell"
                                                                                                                                                            data-selection-id={15316} data-handicap={0} style={{ borderRight: 'none',borderLeft: 'none' }} />
                                                                                                                                                        <td className="biab_bet js-fm-back-1 biab_grey-cell biab_bet-back biab_empty-cell biab_back-1 biab_line-market-cell"
                                                                                                                                                            data-selection-id={15316} data-handicap={0} ><i class="fas fa-arrow-circle-down"></i></td>
                                                                                                                                                        <td className="biab_bet js-fm-back-0 biab_blue-cell biab_bet-back biab_empty-cell biab_back-0 biab_line-market-cell"
                                                                                                                                                            data-selection-id={15316} data-handicap={0}>
                                                                                                                                                            <div className="biab_cell-view">
                                                                                                                                                                <div className="js-bet biab_bet-cell">
                                                                                                                                                                    <div className="biab_bet-content biab_make-offer-wrapper-lg">
                                                                                                                                                                        <span className="biab_make-offer biab_make-offer-lg">Make Offer</span></div>
                                                                                                                                                                </div>
                                                                                                                                                            </div>
                                                                                                                                                        </td>
                                                                                                                                                        <td class="js-lay-cell-0 biab_green-cell biab_bet biab_bet-lay biab_lay-0 biab_line-market-cell" data-id="15316_hc_0" data-selection-id="15316" data-handicap="0">
                                                                                                                                                            <div class="biab_cell-view">
                                                                                                                                                                <div class="js-bet biab_bet-cell" data-toggle="tooltip" data-placement="auto" data-text="This means you can <strong>bet against</strong> Total Runs to be less than runs <strong>or</strong> greater. Your bet would be executed at odds 2.0">
                                                                                                                                                                    <div class="biab_bet-content biab_has-amount">
                                                                                                                                                                        
                                                                                                                                                                        <span class="js-odds biab_odds">22</span>
                                                                                                                                                                        
                                                                                                                                                                        <span class="biab_bet-amount">81</span></div>
                                                                                                                                                                </div>
                                                                                                                                                            </div>
                                                                                                                                                        </td>
                                                                                                                                                        <td class="biab_bet js-fm-lay-1 biab_grey-cell biab_bet-lay biab_empty-cell biab_lay-1 biab_line-market-cell" data-selection-id="15316" data-handicap="0" style={{borderRight: 'none'}}>Min <input id="inpid" class="min-inp" type="number"/></td>
                                                                                                                                                        <td class="biab_bet js-fm-lay-1 biab_grey-cell biab_bet-lay biab_empty-cell biab_lay-1 biab_line-market-cell" data-selection-id="15316" data-handicap="0" style={{borderRight: 'none'}}>Max <input id="inpid" class="min-inp" type="number"/></td>
                                                                                                                                                    </tr>
                                                                                                                                                </tbody>
                                                                                                                                            </table>
                                                                                                                                            <div className="js-inline-placement-component">
                                                                                                                                                <div className="biab_inline-placement" /></div>
                                                                                                                                            <div className="js-mobile-betslip" />
                                                                                                                                            <div className="js-status-wrapper">
                                                                                                                                                <div /></div>
                                                                                                                                        </div>
                                                                                                                                        <div className="biab_market-table-wrapper biab_fancy-market-item">
                                                                                                                                            <table className="biab_market-table">
                                                                                                                                                <colgroup>
                                                                                                                                                    <col />
                                                                                                                                                    <col className="biab_table-col" width="16%" />
                                                                                                                                                    <col className="biab_table-col" width="8%" />
                                                                                                                                                    <col className="biab_table-col" width="8%" />
                                                                                                                                                    <col className="biab_table-col" width="8%" />
                                                                                                                                                    <col className="biab_table-col " width="8%" />
                                                                                                                                                    <col className="biab_table-col " width="8%" />
                                                                                                                                                    <col className="biab_table-col " width="8%" />
                                                                                                                                                </colgroup>
                                                                                                                                                <tbody>
                                                                                                                                                    <tr>
                                                                                                                                                        <td className="biab_fancy-market-selection-name">2nd Innings 25 Over Line</td>
                                                                                                                                                        <td className="biab_fancy-market-selection-tm js-market-total-matched">Min/Max</td>
                                                                                                                                                        <td className="biab_bet js-fm-back-2 biab_grey-cell biab_bet-back biab_empty-cell biab_back-2 biab_line-market-cell"
                                                                                                                                                            data-selection-id={15316} data-handicap={0}>
                                                                                                                                                            <div className="biab_cell-view">
                                                                                                                                                                <div className="js-bet biab_bet-cell">
                                                                                                                                                                    <div className="biab_bet-content " /></div>
                                                                                                                                                            </div>
                                                                                                                                                        </td>
                                                                                                                                                        <td className="biab_bet js-fm-back-1 biab_grey-cell biab_bet-back biab_empty-cell biab_back-1 biab_line-market-cell"
                                                                                                                                                            data-selection-id={15316} data-handicap={0}>
                                                                                                                                                            <div className="biab_cell-view">
                                                                                                                                                                <div className="js-bet biab_bet-cell">
                                                                                                                                                                    <div className="biab_bet-content " /></div>
                                                                                                                                                                <div className="biab_promoted-tooltip-inner ">
                                                                                                                                                                    <div className="biab_promoted-tooltip js-promoted-tooltip biab_hide biab_hide-tooltip">
                                                                                                                                                                        <i className="fas fa-info-circle" data-mobile="true" data-toggle="tooltip" data-text="true" /></div>
                                                                                                                                                                </div>
                                                                                                                                                            </div>
                                                                                                                                                        </td>
                                                                                                                                                        <td className="biab_bet js-fm-back-0 biab_blue-cell biab_bet-back biab_empty-cell biab_back-0 biab_line-market-cell"
                                                                                                                                                            data-selection-id={15316} data-handicap={0}>
                                                                                                                                                            <div className="biab_cell-view">
                                                                                                                                                                <div className="js-bet biab_bet-cell">
                                                                                                                                                                    <div className="biab_bet-content biab_make-offer-wrapper-lg">
                                                                                                                                                                        <span className="biab_make-offer biab_make-offer-lg">Make Offer</span></div>
                                                                                                                                                                </div>
                                                                                                                                                            </div>
                                                                                                                                                        </td>
                                                                                                                                                        <td className="biab_bet js-fm-lay-0 biab_green-cell biab_bet-lay biab_empty-cell biab_lay-0 biab_line-market-cell"
                                                                                                                                                            data-selection-id={15316} data-handicap={0}>
                                                                                                                                                            <div className="biab_cell-view">
                                                                                                                                                                <div className="js-bet biab_bet-cell">
                                                                                                                                                                    <div className="biab_bet-content biab_make-offer-wrapper-lg">
                                                                                                                                                                        <span className="biab_make-offer biab_make-offer-lg">Make Offer</span></div>
                                                                                                                                                                </div>
                                                                                                                                                            </div>
                                                                                                                                                        </td>
                                                                                                                                                        <td className="biab_bet js-fm-lay-1 biab_grey-cell biab_bet-lay biab_empty-cell biab_lay-1 biab_line-market-cell"
                                                                                                                                                            data-selection-id={15316} data-handicap={0}>
                                                                                                                                                            <div className="biab_cell-view">
                                                                                                                                                                <div className="js-bet biab_bet-cell">
                                                                                                                                                                    <div className="biab_bet-content " /></div>
                                                                                                                                                            </div>
                                                                                                                                                        </td>
                                                                                                                                                        <td className="biab_bet js-fm-lay-2 biab_grey-cell biab_bet-lay biab_empty-cell biab_lay-2 biab_line-market-cell"
                                                                                                                                                            data-selection-id={15316} data-handicap={0}>
                                                                                                                                                            <div className="biab_cell-view">
                                                                                                                                                                <div className="js-bet biab_bet-cell">
                                                                                                                                                                    <div className="biab_bet-content " /></div>
                                                                                                                                                            </div>
                                                                                                                                                        </td>
                                                                                                                                                    </tr>
                                                                                                                                                </tbody>
                                                                                                                                            </table>
                                                                                                                                            <div className="js-inline-placement-component">
                                                                                                                                                <div className="biab_inline-placement" /></div>
                                                                                                                                            <div className="js-mobile-betslip" />
                                                                                                                                            <div className="js-status-wrapper">
                                                                                                                                                <div /></div>
                                                                                                                                        </div>
                                                                                                                                        <div className="biab_market-table-wrapper biab_fancy-market-item">
                                                                                                                                            <table className="biab_market-table">
                                                                                                                                                <colgroup>
                                                                                                                                                    <col />
                                                                                                                                                    <col className="biab_table-col" width="16%" />
                                                                                                                                                    <col className="biab_table-col" width="8%" />
                                                                                                                                                    <col className="biab_table-col" width="8%" />
                                                                                                                                                    <col className="biab_table-col" width="8%" />
                                                                                                                                                    <col className="biab_table-col " width="8%" />
                                                                                                                                                    <col className="biab_table-col " width="8%" />
                                                                                                                                                    <col className="biab_table-col " width="8%" />
                                                                                                                                                </colgroup>
                                                                                                                                                <tbody>
                                                                                                                                                    <tr>
                                                                                                                                                        <td className="biab_fancy-market-selection-name">2nd Innings 30 Over Line</td>
                                                                                                                                                        <td className="biab_fancy-market-selection-tm js-market-total-matched">Min/Max</td>
                                                                                                                                                        <td className="biab_bet js-fm-back-2 biab_grey-cell biab_bet-back biab_empty-cell biab_back-2 biab_line-market-cell"
                                                                                                                                                            data-selection-id={15316} data-handicap={0}>
                                                                                                                                                            <div className="biab_cell-view">
                                                                                                                                                                <div className="js-bet biab_bet-cell">
                                                                                                                                                                    <div className="biab_bet-content " /></div>
                                                                                                                                                            </div>
                                                                                                                                                        </td>
                                                                                                                                                        <td className="biab_bet js-fm-back-1 biab_grey-cell biab_bet-back biab_empty-cell biab_back-1 biab_line-market-cell"
                                                                                                                                                            data-selection-id={15316} data-handicap={0}>
                                                                                                                                                            <div className="biab_cell-view">
                                                                                                                                                                <div className="js-bet biab_bet-cell">
                                                                                                                                                                    <div className="biab_bet-content " /></div>
                                                                                                                                                                <div className="biab_promoted-tooltip-inner ">
                                                                                                                                                                    <div className="biab_promoted-tooltip js-promoted-tooltip biab_hide biab_hide-tooltip">
                                                                                                                                                                        <i className="fas fa-info-circle" data-mobile="true" data-toggle="tooltip" data-text="true" /></div>
                                                                                                                                                                </div>
                                                                                                                                                            </div>
                                                                                                                                                        </td>
                                                                                                                                                        <td className="biab_bet js-fm-back-0 biab_blue-cell biab_bet-back biab_empty-cell biab_back-0 biab_line-market-cell"
                                                                                                                                                            data-selection-id={15316} data-handicap={0}>
                                                                                                                                                            <div className="biab_cell-view">
                                                                                                                                                                <div className="js-bet biab_bet-cell">
                                                                                                                                                                    <div className="biab_bet-content biab_make-offer-wrapper-lg">
                                                                                                                                                                        <span className="biab_make-offer biab_make-offer-lg">Make Offer</span></div>
                                                                                                                                                                </div>
                                                                                                                                                            </div>
                                                                                                                                                        </td>
                                                                                                                                                        <td className="biab_bet js-fm-lay-0 biab_green-cell biab_bet-lay biab_empty-cell biab_lay-0 biab_line-market-cell"
                                                                                                                                                            data-selection-id={15316} data-handicap={0}>
                                                                                                                                                            <div className="biab_cell-view">
                                                                                                                                                                <div className="js-bet biab_bet-cell">
                                                                                                                                                                    <div className="biab_bet-content biab_make-offer-wrapper-lg">
                                                                                                                                                                        <span className="biab_make-offer biab_make-offer-lg">Make Offer</span></div>
                                                                                                                                                                </div>
                                                                                                                                                            </div>
                                                                                                                                                        </td>
                                                                                                                                                        <td className="biab_bet js-fm-lay-1 biab_grey-cell biab_bet-lay biab_empty-cell biab_lay-1 biab_line-market-cell"
                                                                                                                                                            data-selection-id={15316} data-handicap={0}>
                                                                                                                                                            <div className="biab_cell-view">
                                                                                                                                                                <div className="js-bet biab_bet-cell">
                                                                                                                                                                    <div className="biab_bet-content " /></div>
                                                                                                                                                            </div>
                                                                                                                                                        </td>
                                                                                                                                                        <td className="biab_bet js-fm-lay-2 biab_grey-cell biab_bet-lay biab_empty-cell biab_lay-2 biab_line-market-cell"
                                                                                                                                                            data-selection-id={15316} data-handicap={0}>
                                                                                                                                                            <div className="biab_cell-view">
                                                                                                                                                                <div className="js-bet biab_bet-cell">
                                                                                                                                                                    <div className="biab_bet-content " /></div>
                                                                                                                                                            </div>
                                                                                                                                                        </td>
                                                                                                                                                    </tr>
                                                                                                                                                </tbody>
                                                                                                                                            </table>
                                                                                                                                            <div className="js-inline-placement-component">
                                                                                                                                                <div className="biab_inline-placement" /></div>
                                                                                                                                            <div className="js-mobile-betslip" />
                                                                                                                                            <div className="js-status-wrapper">
                                                                                                                                                <div /></div>
                                                                                                                                        </div>
                                                                                                                                        <div className="biab_market-table-wrapper biab_fancy-market-item">
                                                                                                                                            <table className="biab_market-table">
                                                                                                                                                <colgroup>
                                                                                                                                                    <col />
                                                                                                                                                    <col className="biab_table-col" width="16%" />
                                                                                                                                                    <col className="biab_table-col" width="8%" />
                                                                                                                                                    <col className="biab_table-col" width="8%" />
                                                                                                                                                    <col className="biab_table-col" width="8%" />
                                                                                                                                                    <col className="biab_table-col " width="8%" />
                                                                                                                                                    <col className="biab_table-col " width="8%" />
                                                                                                                                                    <col className="biab_table-col " width="8%" />
                                                                                                                                                </colgroup>
                                                                                                                                                <tbody>
                                                                                                                                                    <tr>
                                                                                                                                                        <td className="biab_fancy-market-selection-name">2nd Innings 35 Over Line</td>
                                                                                                                                                        <td className="biab_fancy-market-selection-tm js-market-total-matched" />
                                                                                                                                                        <td className="biab_bet js-fm-back-2 biab_grey-cell biab_bet-back biab_empty-cell biab_back-2 biab_line-market-cell"
                                                                                                                                                            data-selection-id={15316} data-handicap={0}>
                                                                                                                                                            <div className="biab_cell-view">
                                                                                                                                                                <div className="js-bet biab_bet-cell">
                                                                                                                                                                    <div className="biab_bet-content " /></div>
                                                                                                                                                            </div>
                                                                                                                                                        </td>
                                                                                                                                                        <td className="biab_bet js-fm-back-1 biab_grey-cell biab_bet-back biab_empty-cell biab_back-1 biab_line-market-cell"
                                                                                                                                                            data-selection-id={15316} data-handicap={0}>
                                                                                                                                                            <div className="biab_cell-view">
                                                                                                                                                                <div className="js-bet biab_bet-cell">
                                                                                                                                                                    <div className="biab_bet-content " /></div>
                                                                                                                                                                <div className="biab_promoted-tooltip-inner ">
                                                                                                                                                                    <div className="biab_promoted-tooltip js-promoted-tooltip biab_hide biab_hide-tooltip">
                                                                                                                                                                        <i className="fas fa-info-circle" data-mobile="true" data-toggle="tooltip" data-text="true" /></div>
                                                                                                                                                                </div>
                                                                                                                                                            </div>
                                                                                                                                                        </td>
                                                                                                                                                        <td className="biab_bet js-fm-back-0 biab_blue-cell biab_bet-back biab_empty-cell biab_back-0 biab_line-market-cell"
                                                                                                                                                            data-selection-id={15316} data-handicap={0}>
                                                                                                                                                            <div className="biab_cell-view">
                                                                                                                                                                <div className="js-bet biab_bet-cell">
                                                                                                                                                                    <div className="biab_bet-content biab_make-offer-wrapper-lg">
                                                                                                                                                                        <span className="biab_make-offer biab_make-offer-lg">Make Offer</span></div>
                                                                                                                                                                </div>
                                                                                                                                                            </div>
                                                                                                                                                        </td>
                                                                                                                                                        <td className="biab_bet js-fm-lay-0 biab_green-cell biab_bet-lay biab_empty-cell biab_lay-0 biab_line-market-cell"
                                                                                                                                                            data-selection-id={15316} data-handicap={0}>
                                                                                                                                                            <div className="biab_cell-view">
                                                                                                                                                                <div className="js-bet biab_bet-cell">
                                                                                                                                                                    <div className="biab_bet-content biab_make-offer-wrapper-lg">
                                                                                                                                                                        <span className="biab_make-offer biab_make-offer-lg">Make Offer</span></div>
                                                                                                                                                                </div>
                                                                                                                                                            </div>
                                                                                                                                                        </td>
                                                                                                                                                        <td className="biab_bet js-fm-lay-1 biab_grey-cell biab_bet-lay biab_empty-cell biab_lay-1 biab_line-market-cell"
                                                                                                                                                            data-selection-id={15316} data-handicap={0}>
                                                                                                                                                            <div className="biab_cell-view">
                                                                                                                                                                <div className="js-bet biab_bet-cell">
                                                                                                                                                                    <div className="biab_bet-content " /></div>
                                                                                                                                                            </div>
                                                                                                                                                        </td>
                                                                                                                                                        <td className="biab_bet js-fm-lay-2 biab_grey-cell biab_bet-lay biab_empty-cell biab_lay-2 biab_line-market-cell"
                                                                                                                                                            data-selection-id={15316} data-handicap={0}>
                                                                                                                                                            <div className="biab_cell-view">
                                                                                                                                                                <div className="js-bet biab_bet-cell">
                                                                                                                                                                    <div className="biab_bet-content " /></div>
                                                                                                                                                            </div>
                                                                                                                                                        </td>
                                                                                                                                                    </tr>
                                                                                                                                                </tbody>
                                                                                                                                            </table>
                                                                                                                                            <div className="js-inline-placement-component">
                                                                                                                                                <div className="biab_inline-placement" /></div>
                                                                                                                                            <div className="js-mobile-betslip" />
                                                                                                                                            <div className="js-status-wrapper">
                                                                                                                                                <div /></div>
                                                                                                                                        </div>
                                                                                                                                        <div className="biab_market-table-wrapper biab_fancy-market-item">
                                                                                                                                            <table className="biab_market-table">
                                                                                                                                                <colgroup>
                                                                                                                                                    <col />
                                                                                                                                                    <col className="biab_table-col" width="16%" />
                                                                                                                                                    <col className="biab_table-col" width="8%" />
                                                                                                                                                    <col className="biab_table-col" width="8%" />
                                                                                                                                                    <col className="biab_table-col" width="8%" />
                                                                                                                                                    <col className="biab_table-col " width="8%" />
                                                                                                                                                    <col className="biab_table-col " width="8%" />
                                                                                                                                                    <col className="biab_table-col " width="8%" />
                                                                                                                                                </colgroup>
                                                                                                                                                <tbody>
                                                                                                                                                    <tr>
                                                                                                                                                        <td className="biab_fancy-market-selection-name">2nd Innings 40 Over Line</td>
                                                                                                                                                        <td className="biab_fancy-market-selection-tm js-market-total-matched" />
                                                                                                                                                        <td className="biab_bet js-fm-back-2 biab_grey-cell biab_bet-back biab_empty-cell biab_back-2 biab_line-market-cell"
                                                                                                                                                            data-selection-id={15316} data-handicap={0}>
                                                                                                                                                            <div className="biab_cell-view">
                                                                                                                                                                <div className="js-bet biab_bet-cell">
                                                                                                                                                                    <div className="biab_bet-content " /></div>
                                                                                                                                                            </div>
                                                                                                                                                        </td>
                                                                                                                                                        <td className="biab_bet js-fm-back-1 biab_grey-cell biab_bet-back biab_empty-cell biab_back-1 biab_line-market-cell"
                                                                                                                                                            data-selection-id={15316} data-handicap={0}>
                                                                                                                                                            <div className="biab_cell-view">
                                                                                                                                                                <div className="js-bet biab_bet-cell">
                                                                                                                                                                    <div className="biab_bet-content " /></div>
                                                                                                                                                                <div className="biab_promoted-tooltip-inner ">
                                                                                                                                                                    <div className="biab_promoted-tooltip js-promoted-tooltip biab_hide biab_hide-tooltip">
                                                                                                                                                                        <i className="fas fa-info-circle" data-mobile="true" data-toggle="tooltip" data-text="true" /></div>
                                                                                                                                                                </div>
                                                                                                                                                            </div>
                                                                                                                                                        </td>
                                                                                                                                                        <td className="biab_bet js-fm-back-0 biab_blue-cell biab_bet-back biab_empty-cell biab_back-0 biab_line-market-cell"
                                                                                                                                                            data-selection-id={15316} data-handicap={0}>
                                                                                                                                                            <div className="biab_cell-view">
                                                                                                                                                                <div className="js-bet biab_bet-cell">
                                                                                                                                                                    <div className="biab_bet-content biab_make-offer-wrapper-lg">
                                                                                                                                                                        <span className="biab_make-offer biab_make-offer-lg">Make Offer</span></div>
                                                                                                                                                                </div>
                                                                                                                                                            </div>
                                                                                                                                                        </td>
                                                                                                                                                        <td className="biab_bet js-fm-lay-0 biab_green-cell biab_bet-lay biab_empty-cell biab_lay-0 biab_line-market-cell"
                                                                                                                                                            data-selection-id={15316} data-handicap={0}>
                                                                                                                                                            <div className="biab_cell-view">
                                                                                                                                                                <div className="js-bet biab_bet-cell">
                                                                                                                                                                    <div className="biab_bet-content biab_make-offer-wrapper-lg">
                                                                                                                                                                        <span className="biab_make-offer biab_make-offer-lg">Make Offer</span></div>
                                                                                                                                                                </div>
                                                                                                                                                            </div>
                                                                                                                                                        </td>
                                                                                                                                                        <td className="biab_bet js-fm-lay-1 biab_grey-cell biab_bet-lay biab_empty-cell biab_lay-1 biab_line-market-cell"
                                                                                                                                                            data-selection-id={15316} data-handicap={0}>
                                                                                                                                                            <div className="biab_cell-view">
                                                                                                                                                                <div className="js-bet biab_bet-cell">
                                                                                                                                                                    <div className="biab_bet-content " /></div>
                                                                                                                                                            </div>
                                                                                                                                                        </td>
                                                                                                                                                        <td className="biab_bet js-fm-lay-2 biab_grey-cell biab_bet-lay biab_empty-cell biab_lay-2 biab_line-market-cell"
                                                                                                                                                            data-selection-id={15316} data-handicap={0}>
                                                                                                                                                            <div className="biab_cell-view">
                                                                                                                                                                <div className="js-bet biab_bet-cell">
                                                                                                                                                                    <div className="biab_bet-content " /></div>
                                                                                                                                                            </div>
                                                                                                                                                        </td>
                                                                                                                                                    </tr>
                                                                                                                                                </tbody>
                                                                                                                                            </table>
                                                                                                                                            <div className="js-inline-placement-component">
                                                                                                                                                <div className="biab_inline-placement" /></div>
                                                                                                                                            <div className="js-mobile-betslip" />
                                                                                                                                            <div className="js-status-wrapper">
                                                                                                                                                <div /></div>
                                                                                                                                        </div>
                                                                                                                                        <div className="biab_market-table-wrapper biab_fancy-market-item">
                                                                                                                                            <table className="biab_market-table">
                                                                                                                                                <colgroup>
                                                                                                                                                    <col />
                                                                                                                                                    <col className="biab_table-col" width="16%" />
                                                                                                                                                    <col className="biab_table-col" width="8%" />
                                                                                                                                                    <col className="biab_table-col" width="8%" />
                                                                                                                                                    <col className="biab_table-col" width="8%" />
                                                                                                                                                    <col className="biab_table-col " width="8%" />
                                                                                                                                                    <col className="biab_table-col " width="8%" />
                                                                                                                                                    <col className="biab_table-col " width="8%" />
                                                                                                                                                </colgroup>
                                                                                                                                                <tbody>
                                                                                                                                                    <tr>
                                                                                                                                                        <td className="biab_fancy-market-selection-name">2nd Innings 45 Over Line</td>
                                                                                                                                                        <td className="biab_fancy-market-selection-tm js-market-total-matched" />
                                                                                                                                                        <td className="biab_bet js-fm-back-2 biab_grey-cell biab_bet-back biab_empty-cell biab_back-2 biab_line-market-cell"
                                                                                                                                                            data-selection-id={15316} data-handicap={0}>
                                                                                                                                                            <div className="biab_cell-view">
                                                                                                                                                                <div className="js-bet biab_bet-cell">
                                                                                                                                                                    <div className="biab_bet-content " /></div>
                                                                                                                                                            </div>
                                                                                                                                                        </td>
                                                                                                                                                        <td className="biab_bet js-fm-back-1 biab_grey-cell biab_bet-back biab_empty-cell biab_back-1 biab_line-market-cell"
                                                                                                                                                            data-selection-id={15316} data-handicap={0}>
                                                                                                                                                            <div className="biab_cell-view">
                                                                                                                                                                <div className="js-bet biab_bet-cell">
                                                                                                                                                                    <div className="biab_bet-content " /></div>
                                                                                                                                                                <div className="biab_promoted-tooltip-inner ">
                                                                                                                                                                    <div className="biab_promoted-tooltip js-promoted-tooltip biab_hide biab_hide-tooltip">
                                                                                                                                                                        <i className="fas fa-info-circle" data-mobile="true" data-toggle="tooltip" data-text="true" /></div>
                                                                                                                                                                </div>
                                                                                                                                                            </div>
                                                                                                                                                        </td>
                                                                                                                                                        <td className="biab_bet js-fm-back-0 biab_blue-cell biab_bet-back biab_empty-cell biab_back-0 biab_line-market-cell"
                                                                                                                                                            data-selection-id={15316} data-handicap={0}>
                                                                                                                                                            <div className="biab_cell-view">
                                                                                                                                                                <div className="js-bet biab_bet-cell">
                                                                                                                                                                    <div className="biab_bet-content biab_make-offer-wrapper-lg">
                                                                                                                                                                        <span className="biab_make-offer biab_make-offer-lg">Make Offer</span></div>
                                                                                                                                                                </div>
                                                                                                                                                            </div>
                                                                                                                                                        </td>
                                                                                                                                                        <td className="biab_bet js-fm-lay-0 biab_green-cell biab_bet-lay biab_empty-cell biab_lay-0 biab_line-market-cell"
                                                                                                                                                            data-selection-id={15316} data-handicap={0}>
                                                                                                                                                            <div className="biab_cell-view">
                                                                                                                                                                <div className="js-bet biab_bet-cell">
                                                                                                                                                                    <div className="biab_bet-content biab_make-offer-wrapper-lg">
                                                                                                                                                                        <span className="biab_make-offer biab_make-offer-lg">Make Offer</span></div>
                                                                                                                                                                </div>
                                                                                                                                                            </div>
                                                                                                                                                        </td>
                                                                                                                                                        <td className="biab_bet js-fm-lay-1 biab_grey-cell biab_bet-lay biab_empty-cell biab_lay-1 biab_line-market-cell"
                                                                                                                                                            data-selection-id={15316} data-handicap={0}>
                                                                                                                                                            <div className="biab_cell-view">
                                                                                                                                                                <div className="js-bet biab_bet-cell">
                                                                                                                                                                    <div className="biab_bet-content " /></div>
                                                                                                                                                            </div>
                                                                                                                                                        </td>
                                                                                                                                                        <td className="biab_bet js-fm-lay-2 biab_grey-cell biab_bet-lay biab_empty-cell biab_lay-2 biab_line-market-cell"
                                                                                                                                                            data-selection-id={15316} data-handicap={0}>
                                                                                                                                                            <div className="biab_cell-view">
                                                                                                                                                                <div className="js-bet biab_bet-cell">
                                                                                                                                                                    <div className="biab_bet-content " /></div>
                                                                                                                                                            </div>
                                                                                                                                                        </td>
                                                                                                                                                    </tr>
                                                                                                                                                </tbody>
                                                                                                                                            </table>
                                                                                                                                            <div className="js-inline-placement-component">
                                                                                                                                                <div className="biab_inline-placement" /></div>
                                                                                                                                            <div className="js-mobile-betslip" />
                                                                                                                                            <div className="js-status-wrapper">
                                                                                                                                                <div /></div>
                                                                                                                                        </div>
                                                                                                                                        <div className="biab_market-table-wrapper biab_fancy-market-item">
                                                                                                                                            <table className="biab_market-table">
                                                                                                                                                <colgroup>
                                                                                                                                                    <col />
                                                                                                                                                    <col className="biab_table-col" width="16%" />
                                                                                                                                                    <col className="biab_table-col" width="8%" />
                                                                                                                                                    <col className="biab_table-col" width="8%" />
                                                                                                                                                    <col className="biab_table-col" width="8%" />
                                                                                                                                                    <col className="biab_table-col " width="8%" />
                                                                                                                                                    <col className="biab_table-col " width="8%" />
                                                                                                                                                    <col className="biab_table-col " width="8%" />
                                                                                                                                                </colgroup>
                                                                                                                                                <tbody>
                                                                                                                                                    <tr>
                                                                                                                                                        <td className="biab_fancy-market-selection-name">2nd Innings Runs Line</td>
                                                                                                                                                        <td className="biab_fancy-market-selection-tm js-market-total-matched" />
                                                                                                                                                        <td className="biab_bet js-fm-back-2 biab_grey-cell biab_bet-back biab_empty-cell biab_back-2 biab_line-market-cell"
                                                                                                                                                            data-selection-id={15316} data-handicap={0}>
                                                                                                                                                            <div className="biab_cell-view">
                                                                                                                                                                <div className="js-bet biab_bet-cell">
                                                                                                                                                                    <div className="biab_bet-content " /></div>
                                                                                                                                                            </div>
                                                                                                                                                        </td>
                                                                                                                                                        <td className="biab_bet js-fm-back-1 biab_grey-cell biab_bet-back biab_empty-cell biab_back-1 biab_line-market-cell"
                                                                                                                                                            data-selection-id={15316} data-handicap={0}>
                                                                                                                                                            <div className="biab_cell-view">
                                                                                                                                                                <div className="js-bet biab_bet-cell">
                                                                                                                                                                    <div className="biab_bet-content " /></div>
                                                                                                                                                                <div className="biab_promoted-tooltip-inner ">
                                                                                                                                                                    <div className="biab_promoted-tooltip js-promoted-tooltip biab_hide biab_hide-tooltip">
                                                                                                                                                                        <i className="fas fa-info-circle" data-mobile="true" data-toggle="tooltip" data-text="true" /></div>
                                                                                                                                                                </div>
                                                                                                                                                            </div>
                                                                                                                                                        </td>
                                                                                                                                                        <td className="biab_bet js-fm-back-0 biab_blue-cell biab_bet-back biab_empty-cell biab_back-0 biab_line-market-cell"
                                                                                                                                                            data-selection-id={15316} data-handicap={0}>
                                                                                                                                                            <div className="biab_cell-view">
                                                                                                                                                                <div className="js-bet biab_bet-cell">
                                                                                                                                                                    <div className="biab_bet-content biab_make-offer-wrapper-lg">
                                                                                                                                                                        <span className="biab_make-offer biab_make-offer-lg">Make Offer</span></div>
                                                                                                                                                                </div>
                                                                                                                                                            </div>
                                                                                                                                                        </td>
                                                                                                                                                        <td className="biab_bet js-fm-lay-0 biab_green-cell biab_bet-lay biab_lay-0 biab_line-market-cell" data-selection-id={15316}
                                                                                                                                                            data-handicap={0}>
                                                                                                                                                            <div className="biab_cell-view">
                                                                                                                                                                <div className="js-bet biab_bet-cell" data-toggle="tooltip" data-placement="auto" data-text="<strong>Bet up to 345.11  against</strong> Total Runs to be less than runs in the order to be matched instantly <strong>or</strong> choose your own runs and stake waiting for someone to accept your bet. Note, that your bet would be always executed at odds 2.0">
                                                                                                                                                                    <div className="biab_bet-content biab_has-amount">
                                                                                                                                                                        <span className="js-odds biab_odds">240</span>
                                                                                                                                                                    <span className="biab_bet-amount">345</span></div>
                                                                                                                                                                </div>
                                                                                                                                                            </div>
                                                                                                                                                        </td>
                                                                                                                                                        <td className="biab_bet js-fm-lay-1 biab_grey-cell biab_bet-lay biab_empty-cell biab_lay-1 biab_line-market-cell"
                                                                                                                                                            data-selection-id={15316} data-handicap={0}>
                                                                                                                                                            <div className="biab_cell-view">
                                                                                                                                                                <div className="js-bet biab_bet-cell">
                                                                                                                                                                    <div className="biab_bet-content " /></div>
                                                                                                                                                            </div>
                                                                                                                                                        </td>
                                                                                                                                                        <td className="biab_bet js-fm-lay-2 biab_grey-cell biab_bet-lay biab_empty-cell biab_lay-2 biab_line-market-cell"
                                                                                                                                                            data-selection-id={15316} data-handicap={0}>
                                                                                                                                                            <div className="biab_cell-view">
                                                                                                                                                                <div className="js-bet biab_bet-cell">
                                                                                                                                                                    <div className="biab_bet-content " /></div>
                                                                                                                                                            </div>
                                                                                                                                                        </td>
                                                                                                                                                    </tr>
                                                                                                                                                </tbody>
                                                                                                                                            </table>
                                                                                                                                            <div className="js-inline-placement-component">
                                                                                                                                                <div className="biab_inline-placement" /></div>
                                                                                                                                            <div className="js-mobile-betslip" />
                                                                                                                                            <div className="js-status-wrapper">
                                                                                                                                                <div /></div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="js-batsman-markets-list-region">
                                                                                                                            <div>
                                                                                                                                <div className=" biab_fancy-swap">
                                                                                                                                    <div className="js-fancy-markets-list" /></div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="biab_multi-markets-content-message js-markets-message biab_hide">
                                                                                                <div>There are no markets to be displayed</div>
                                                                                            </div>
                                                                                            <div className="biab_clear" /></div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="js-quick-panel" />
                                                                        <div className="js-mobile-placement-wrapper" /></div>
                                                                    <div className="biab_overlay js-overlay" />
                                                                    <div className="js-event-rules" /></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   </React.Fragment>
    )
}

export default Fancy
