import React from "react";
import { Header } from "./Header";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import "./App.css";

function App() {

  const isMobileDevice =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
const isMobileDevice1 = window.matchMedia("(max-width: 767px)").matches;

const isMobile = isMobileDevice || isMobileDevice1 ? true : false;

  return (
    <React.Fragment>
      <nav className="Navi"></nav>
      {
        
        // isMobile?<h1 style ={{textAlign:"center", marginTop:"50%"}}>MAINTENANCE</h1>:
        isMobile?<h1 style ={{position:'absolute',left:'20%',right:'50%', top:'50%',color:'red'}}>MAINTENANCE</h1>:
      
      <Router>
        <Switch>
          <Route exact path="/" component={Header}></Route>
          <Route exact path="/:eventType" component={Header}></Route>
          <Route exact path="/:eventType/:sportId" component={Header}></Route>
          <Route
            exact
            path="/:eventType/:eventId/:marketId"
            component={Header}
          ></Route>
          <Route
            exact
            path="/:eventType/:sportType/:eventId/:marketId"
            component={Header}
          ></Route>
          <Route
            exact
            path="/:eventType/:eventId/:marketId/:selectionId/:fancy_id"
            component={Header}
          ></Route>
        </Switch>
      </Router>
}
    </React.Fragment>
  );
}

export default App;
